.rt-lblock {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  z-index: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: 0 7px 0 0;
  padding: 0 3px;
}

.rt-block {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  z-index: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin: 0 7px 0 0;
}

.rt-beta {
  background: #7FA5C4;
}

.rt-alpha {
  background: #E49788;
}

.rt-release {
  background: #8CAF62;
}

.new_file {
  background-color: gold;
}

.rt_release.newest_release, .rt_beta.newest_beta, .rt_alpha.newest_alpha .file_name {
  font-weight: bold;
  text-decoration: underline;
}
